<template>
  <v-app style="height: 100%">
    <div class="img2">
      <v-container top="60">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2FCADT-LOGO-LONG.png?alt=media&token=808e61aa-8f62-41af-9f9e-5e7b7db8f86c"
          style="height: 10vh"
        />
        <h2 class="text1">School Management System</h2>
        <section id="firebaseui-auth-container"></section>
      </v-container>
    </div>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>

<script>
import firebase from "firebase";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { login } from "@schoolbase/web-client-lib";

export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data() {
    return {
      mode: "",
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      myLoading: false,
      isLoading: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  mounted() {
    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
    }
    const self = this;
    var uiConfig = {
      signInFlow: "popup",
      signInSuccessUrl: "", // redirect to self

      callbacks: {
        signInSuccessWithAuthResult: async function () {
          const token = await firebase.auth().currentUser.getIdToken();
          if (!token) {
            window.console.log("No token");
          } else {
            try {
              self.isLoading = true;
              self.myLoading = true;
              const ApiResponse = await login(token);
              const roles = ApiResponse.payload.roles;
              const saveToken = ApiResponse.payload.token;
              if (roles && roles.length > 0) {
                if (roles.length > 1) {
                  self.$router.replace({ name: "RoleDashboard" });
                  self.$store.commit("LOGGED_USER", ApiResponse);
                  self.$store.commit("LOGGED_TOKEN", saveToken);
                } else {
                  const accessRole = roles[0];
                  if (accessRole.value == "super_admin") {
                    self.$store.commit("LOGGED_USER", ApiResponse);
                    self.$store.commit("LOGGED_TOKEN", saveToken);
                    self.$router.replace({ name: "ManageUser" });
                  } else if (accessRole.value == "top_manager") {
                    self.$store.commit("LOGGED_USER", ApiResponse);
                    self.$store.commit("LOGGED_TOKEN", saveToken);
                    self.$router.replace({ name: "TopManagerDashboard" });
                  } else if (accessRole.value == "tsc") {
                    self.$store.commit("LOGGED_TOKEN", saveToken);
                    self.$store.commit("LOGGED_USER", ApiResponse);
                    self.$router.replace({ name: "TscDashboard" });
                  } else if (accessRole.value == "lecturer") {
                    self.$store.commit("LOGGED_TOKEN", saveToken);
                    self.$store.commit("LOGGED_USER", ApiResponse);
                    self.$router.replace({ name: "Session" });
                  } else if (accessRole.value == "student") {
                    self.$store.commit("LOGGED_TOKEN", saveToken);
                    self.$store.commit("LOGGED_USER", ApiResponse);
                    self.$router.replace({ name: "StudentDashboards" });
                  } else if (accessRole.value.includes("major_admin")) {
                    self.$store.commit("LOGGED_TOKEN", saveToken);
                    self.$store.commit("LOGGED_USER", ApiResponse);
                    self.$router.replace({ name: "ManageMajorGen" });
                  } else {
                    self.$router.replace({ name: "Anonymous" });
                  }
                }
              } else {
                self.$router.replace({ name: "Anonymous" });
              }

              // Update UIs
              self.myLoading = false;
              self.isLoading = false;
            } catch (e) {
              self.myLoading = false;
              self.isLoading = false;
              window.console.error(e);
            }
          }

          return true;
        },
      },
      signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    };
    ui.start("#firebaseui-auth-container", uiConfig);
  },
  components: {
    Loading,
  },
  methods: {},
};
</script>

<style scoped lang="css">
.img {
  width: 100%;
}

.text1 {
  color: #fff;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 80px;
  letter-spacing: 3px;
  font-family: aileron, sans-serif;
  position: relative;
  font-weight: 700;
  text-align: center;
  font-size: 4vw;
}

.container {
  align-items: center;
  text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  margin: 1em auto 2em;
}
.img2 {
  height: 100%;
  background: url("https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Fbg.jpg?alt=media&token=8ca50be3-8efd-4b87-975d-ebed7f3b5e20");
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.social-button {
  width: 75px;
  background: white;
  padding: 10px;
  border-radius: 100%;
  box-shadow: 0 2px 4px 0 rgb(0, 0, 0, 0, 2);
  outline: 0;
  border: 0;
}
.social-button:active {
  box-shadow: 0 2px 4px 0 rgb(0, 0, 0, 0.1);
}
.vld-overlay {
  transition: 0.4s ease-in !important;
}
</style>
